@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #F9FAFB
}

.form{
  max-width: 1900px
}

.miniForm{
  max-width: 1300px
}
.formStage {
  display: flex;
  margin: 0 auto 20px;
}

@media (max-width: 800px) {
  .infoModal {
    font-size: 15px;
    gap: 20px;
  }
}
@media (max-width: 800px) {
  .formStage {
    overflow-x: hidden;
    margin: 0;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
  }
  .formStage a {
    min-width: 100%;
  }
}

@media (max-width: 1700px) {
  .formStage {
    overflow-x: hidden;
    margin: 0;
    margin-bottom: 20px;
    align-items: center;
  }
  .formStage a {
    min-width: 100%;
  }
}



.loader {
  border-top-color: #4F46E5;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}
.scroll-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tableHoverGray tr:hover td {
  background-color: #F3F4F6;
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  color: #9CA3AF;
}
input[type="date"].date-input--has-value::-webkit-datetime-edit-text,
input[type="date"].date-input--has-value::-webkit-datetime-edit-month-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-day-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-year-field {
  color: black;
}

.dropdown-enter {
  opacity: 0;
}
.dropdown-enter-active {
  transition: opacity 350ms ease-out;
  opacity: 1;
}
.dropdown-exit-active {
  transition: opacity 350ms ease-out;
  opacity: 0;
}
.alert {
  z-index: 9999;
}

.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  transition: opacity 500ms ease-out;
  opacity: 1;
}
.alert-exit-active {
  transition: opacity 350ms ease-out;
  opacity: 0;
}

.groupArrow{
  transform: rotate(180deg);
  transition: transform 0.2s linear;
}

.groupArrow.open{
  transform: rotate(90deg);
  transition: transform 0.2s linear;
}
.notification{
  max-height: 80vh;
  z-index: 1000;
}
.text-2xs {
  font-size: 0.6rem;
  line-height: 0.75rem;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.bounce-right{
  animation: bounce-right 1s infinite;
}

@keyframes bounce-right {
  0%, 100% {
    transform: translateX(-25%);
    animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animationTimingFunction: cubic-bezier(0, 0, 0.2, 1);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.tooltip-container {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
}

.tooltip-container.tooltip-visible {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.search-results {
  width: 200%;
  z-index: 9999;
}

progress {
  height: 5px;
  /*margin-top: 25px;*/
  /*border: none;*/
  background-color: #286C2D;
  border-radius: 15px;
}

progress::-webkit-progress-bar {
  border-radius: 15px;
  background-color: black;
}

progress::-webkit-progress-value {
  border-radius: 15px;
  background-color: #41E847;
}

progress::-moz-progress-bar {
  border-radius: 15px;
  background-color: #41E847;
}